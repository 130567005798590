.dropzone {
    margin-top: 16px;
    height: 100px;
    border: 2px #444 dashed;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
}

.dropzone:hover {
    background: #2a2a2a;
}

.dropzone p {
    margin: 0;
    display: inline;
    color: #999;
    font-weight: 600;
    font-size: .9em;
}

.fileList>li {
    font-weight: 600;
    font-size: .9em;
}
.fileList>li>button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: .4em;
    padding: 6px;
    font-weight: 800;
    color: #999;
}