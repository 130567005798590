.fileList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-left: 1em;
    margin-top: 1em;
}

.fileList>li {
    margin: .2em;
    position: relative;
    color: #fafafa;
    font-size: .9em
}

input[type=checkbox] {
    margin-right: .6em;
    cursor: pointer;
}

input[type=checkbox]::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: .9em;
    width: .9em;
    border-radius: 4px;
    border: 2px #444 solid;
    background: #1a1a1a;
}
input[type=checkbox]:checked::after {
    background: #ea4a92;
    border-color: #ea4a92;
}

h5 {
    margin: 0;
    margin-top: 1em;
    margin-bottom: .3em;
    font-weight: 800;
    color: #ccc;
}