.reviewList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.reviewList>li {
    margin-bottom: 12px;
}

.reviewCardLink {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
}

.reviewCardContent {
    position: relative;
    pointer-events: none;
    z-index: 1;
}
.reviewCardContent a {
    pointer-events: all;
}

.reviewCard {
    padding: 10px;
    background: #222 !important;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .3);
    border-radius: 8px;
    position: relative;
}

.reviewCard:hover {
    border: 2px solid #ea4a92;
    padding: 8px;
}

.reviewCard .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .8em;
    color: #aaa;
}

.reviewCard h3 {
    margin: 0 0 0 0;
    margin-top: .4em;
    display: inline-block;
    margin-right: 6px;
}

.reviewCard .description {
    font-size: 1em;
    color: #bbb;
    margin: .2em 0 0 0;
    line-height: 1.25em;
    vertical-align: top;
}

.repoUrl {
    font-size: .8em;
    color: #aaa;
    background: none;
    border: none;
    cursor: pointer;
}
.repoUrl:hover {
    color:  #ea4a92;
}

.avatar {
    height: 16px;
    width: 16px;
    border-radius: 16px;
}

.userName {
    margin: 0 .4em;
    font-weight: 800;
}

.createdAt {
    margin: 0px 8px;
}

.spacer {
    flex-grow: 1;
}

.comments {
    font-weight: 800;
}

.language {
    border-radius: 20px;
    font-size: .7em;
    font-weight: 600;
    background: rgb(27, 159, 252);
    color: white;
    padding: 1px 6px;
    margin-right: 4px;
}
