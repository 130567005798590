.hljs {
    background: #1d1d1d !important;
}

p > code.hljs {
    display: inline;
}

html body {
    background: #111;
    color: #fafafa;
    font-family: 'Inter', sans-serif;
}

body {
    padding: 0;
    padding-top: 48px;
}

h1 {
    font-size: 2.4em;
    font-weight: 800;
    margin-bottom: .25em;
    letter-spacing: -.04em;
}

h2 {
    font-size: 2.0em;
    font-weight: 800;
    margin-bottom: .2em;
    letter-spacing: -.04em;
}

h3 {
    font-size: 1.4em;
    font-weight: 800;
    margin-bottom: .15em;
    letter-spacing: -.03em;
}

h4 {
    font-size: 1.2em;
    font-weight: 800;
    margin-bottom: .15em;
    letter-spacing: -.03em;
}

section {
    max-width: 800px;
    margin: 0 auto;
    padding: 8px;
}

a {
    color: white;
}

@media only screen and (max-width: 600px) {
    .hiddenMobile {
        display: none !important;
    }

    h1 {
        font-size: 2.2em;
    }
    h2 {
        font-size: 1.8em;
    }
    h3 {
        font-size: 1.3em;
    }
    h4 {
        font-size: 1.15em;
    }
}
@media only screen and (min-width: 600px) {
    .hiddenDesktop {
        display: none !important;
    }
}