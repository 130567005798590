.hero {
    background: #62006b;
    display: flex;
}

.heroContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.heroText {
    max-width: 70%;
    color: #eee;
    font-weight: 500;
    line-height: 1.2;
    padding: 12px 0;
}

.heroButton {
    height: 30px;
    margin: 6px;
    line-height: 30px;
    border-radius: 22px;
    padding: 0px 22px;
    display: block;
    text-align: center;
    color: white;
    text-decoration: none;
    font-weight: 700;
    background-color: #ea4a92;
}
.heroButton:hover {
    line-height: 27px;
    padding: 0px 20px;
    border: 2px white solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
    .heroContent {
        flex-direction: column;
        align-items: flex-start;
    }
    .heroText {
        max-width: 100%;
        padding: 8px 8px;
    }
}


.loadMoreButton {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #ea4a92;
    font-weight: 600;
    width: 100%;
}
.loadMoreButton:hover {
    text-decoration: underline;
}
.loadMoreButton[disabled] {
    opacity: .3;
    cursor: initial;
    text-decoration: none;
}