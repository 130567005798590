.file code {
    font-size: 14px;
    line-height: 17px;
}

.file pre {
    margin:0;
}

.wrapper {
    position: relative;
    width: 800px;
    background: #1c1c1c;
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .3);
}

.line {
    width: 800px;
    font-size: 14px;
    line-height: 17px;
    color: transparent;
    margin: 0 !important;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.line:hover {
    background: rgba(255, 0, 255, .3);
    cursor: pointer;
}

.selected {
    background: rgba(255, 0, 255, .2)
}
.line::selection {
    background: rgba(255, 0, 255, .2)
}

.commentPane {
    position: absolute;
    top: 0;
    margin-left: calc(100% - 8px);
    padding: 8px;
}

.comment {
    margin: 4px;
    padding: 0px;
}

.commentContent {
    margin: 0;
    z-index: 20;
    font-weight: 500;
    font-size: .9em;
    width: auto;
    height: auto;
    width: 400px;
}

.commentInput {
    border-radius: 6px;
    border-left: 4px #a0e solid;
    padding: 6px;
    background: #333;
    display: block;
}

.commentInput>form>textarea {
    width: calc(100% - 10px);
    background: #2a2a2a;
    border: 2px solid #444;
    border-radius: 4px;
    outline: none;
    color: #fafafa;
    font-weight: 500;
    resize: none;
    display: block;
}

.replyFold {
    padding: 2px;
    padding-left: 28px;
    border-top: 2px #333 dashed;
    border-bottom: 2px #333 dashed;
    background: #212121;
    font-weight: 600;
    color: #888;
    font-size: .8em;
}

.commentText:hover .replyFold {
    border-color: #555;
    background-color: transparent;
}

.commentText:hover .commentReplyButton {
    opacity: .6;
    background: #a0e;
}

.commentReplyButton:hover {
    opacity: 1 !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5);
}

.commentReplyButton {
    position: absolute;
    opacity: .1;
    right: 0;
    bottom: 0;
    background: transparent;
    border: none;
    padding: 4px;
    font-size: .8em;
    color: white;
    border-radius: 6px 0 6px 0;
    font-weight: 800;
    cursor: pointer;
}

.commentCloseButton:hover {
    opacity: 1 !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5);
}

.commentCloseButton {
    position: absolute;
    opacity: .6;
    background: #a0e;
    right: 0;
    top: 0;
    border: none;
    padding: 4px;
    font-size: .8em;
    color: white;
    border-radius: 0 0 0 6px;
    font-weight: 800;
    cursor: pointer;
}

.commentContent>div>form {
    background: #333;
    box-shadow: none;
}

.commentContent>div>form>textarea:hover {
    border: 2px solid #555;
}

.commentContent>div>form>textarea:focus {
    border: 2px solid #70a;
}

.commentContent>div>form>textarea:active {
    border: 2px solid #90c;
}

.commentText {
    position: relative;
    padding: 6px;
    border-left: 4px #a0e solid;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 1);
    background: #262626;
    cursor: pointer;
}
.commentText:hover {
    background: #3a3a3a;
}

.selectedCommentText {
    padding-top: 16px;
    padding-right: 24px;
    position: fixed;
    cursor: initial;
    top: 48px;
    height: calc(100% - 48px);
    z-index: 100;
    background: #242424 !important;
    box-shadow: -4px 8px 6px 0 rgba(0, 0, 0, 1);
    border-radius: 0;
}

.commentRow {
    display: flex;
    margin-bottom: 12px;
}

.commentForm {
    padding-left: 28px;
}

.commentForm textarea {
    width: calc(100% - 10px);
    min-width: 480px;
    background: #2a2a2a;
    border: 2px solid #444;
    border-radius: 4px;
    outline: none;
    color: #fafafa;
    font-weight: 500;
    resize: none;
    display: block;
}

.commentButton {
    outline: none !important;
    font-weight: 700;
    background-color: #ea4a92;
    text-align: center;
    padding: 4px 16px;
    display: block;
    border: none;
    color: #fef;
    border-radius: 4px;
    margin-top: 8px;
    font-size: .9em;
    cursor: pointer;
}

.commentButton:hover {
    background-color: #ea4a92;
    padding: 2px 14px;
    border: 2px solid white;
    margin-top: 4px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.commentMain form {
    padding: 0;
    margin-top: 8px;
}

.commentMain>p {
    margin: 0;
}

.commentTextHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .75em;
    color: #888;
}

.commentMain {
    width: calc(100% - 50px);
}

.avatar {
    height: 20px;
    width: 20px;
    border-radius: 16px;
    margin-right: 8px;
}

.userName {
    font-weight: 800;
    color: #888;
}

.createdAt {
    margin: 0px 2px;
}

.markdown {
    font-size: .85rem;
}

.markdown h1 {
    font-size: 1.4em;
}
.markdown h1:after,.markdown h2:after {
    content: '';
    width: 100%;
    display: block;
    margin-top: .15em;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    border-radius: 2px;
}

.markdown h2 {
    font-size: 1.2em;
}

.markdown h3 {
    font-size: 1.2em;
}

.markdown h4 {
    font-size: 1.1em;
}

.markdown h5 {
    font-size: 1em;

}

.markdown h6 {
    font-size: 1em;
    color: #aaa;
    margin-top: 1em;
    margin-bottom: .3em;
}

.markdown pre {
    background: #2a2a2a;
    border-radius: 4px;
    padding: 4px 6px;
    margin-top: .3em;
    margin-bottom: .3em;
}

.markdown code {
    border-radius: 2px;
    padding: 0 4px;
    background: #3a3a3a !important;
}

.markdown pre {
    padding: 4px;
    background: #3a3a3a !important;
}
.markdown pre>code {
    background: none;
    padding: 0;
}

.markdown p {
    margin: 0;
    margin-top: .3em;
}

.markdown img {
    max-width: 100%;
}

.markdown blockquote {
    border-left: 4px #888 solid;
    background: #3c3c3c;
    color: #ccc;
    margin: 0.8em 0;
    padding: 2px 4px;
    border-radius: 4px;
}

.markdown blockquote>p {
    margin: 0;
}

.markdown hr {
    border: 2px #444 solid;
    border-radius: 2px;
}

.markdown ul {
    padding-inline-start: 32px;
    margin-block-start: .4em;
    margin-block-end: .8em;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}