.loginCard {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .4);
    background: #222;
    padding: 16px;
    margin: 48px;
    border-radius: 8px;
}

.loginCard>h2 {
    color: #ddd;
    margin-top: 0;
    font-size: 2.4em;
    margin-bottom: .4em;
}

.loginCard>p {
    line-height: 1.2em;
    font-weight: 500;
    color: #aaa;
    margin-bottom: 1.8em;
}

.loginCard>button {
    background: #a0e;
    padding: 12px 24px;
    color: white;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, .3);
    font-weight: 800;
    cursor: pointer;
}

.loginCard>button>svg {
    margin-right: 12px;
}

.loginCard>button:hover {
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, .5);
    padding: 10px 22px;
    border: 2px white solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}