.navbar>div>ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.navbar>div>ul>* {
    padding: 0;
}

.navLink.secondary{
    color: white;
    background-color: transparent;
}

.navLink {
    height: 36px;
    margin: 6px;
    line-height: 36px;
    border-radius: 24px;
    padding: 0px 32px;
    display: block;
    text-align: center;
    color: white;
    text-decoration: none;
    font-weight: 700;
    background-color: #ea4a92;
}

.navLink:hover {
    line-height: 33px;
    padding: 0px 30px;
    border: 2px white solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.navbar>div {
    display: flex;
    flex-direction: row;
    height: 48px;
}

.navbar {
    height: 48px;
    background: #222;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

.logo {
    height: 28px;
    margin: 10px;
}

.avatar {
    height: 36px;
    margin: 6px;
    margin-right: 12px;
    width: 36px;
    border-radius: 20px;
}

.dropdown {
    position: relative;
    padding: 0;
}

.dropdown>button {
    padding: 0;
    outline: none;
    height: 48px;
    border: none;
    background: transparent;
    margin-right: 4px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.9);
    z-index: 1;
    right: 8px;
    border-radius: 6px;
    background: #444;
    padding: 4px;
  }

.dropdownContent a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 6px;
    font-weight: 600;
}

.dropdownContent hr {
    border: 2px solid #555;
    border-radius: 4px;
}
  
.dropdownContent a:hover {background-color: #4f4f4f;}
.dropdown:hover .dropdownContent {display: block;}
.dropdown:active .dropdownContent {display: block;}

@media only screen and (max-width: 600px) {
    .navLink {
        margin: 6px 4px;
        line-height: 36px;
        padding: 0px 12px;
    }

    .navLink:hover {
        padding: 0px 10px;
    }
    .avatar {
        margin: 6px;
    }
    .logo {
        height: 34px;
        margin: 6px;
    }
}