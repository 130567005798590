.codeSourceTabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.codeSourceTabs>div {
    flex-grow: 1;
    border-bottom: 2px solid #444;
}
.codeSourceTabs>button {
    padding: 12px 20px;
    border: none;
    outline: none;
    border-bottom: 2px solid #444;
    border-top: 2px solid transparent;
    cursor: pointer;
    background: transparent;
    color: #aaa;
    font-weight: 800;
    border-radius: 6px 6px 0 0;
}
.codeSourceTabs>button:hover {
    background: #333;
}
.codeSourceTabs .active {
    border: 2px solid #444;
    border-bottom: 2px solid transparent;
    color: #fafafa;
}

.submitPage h1 {
    font-size: 3em;
}

.submitPage form {
    background: #222;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, .3);
}

.submitPage h5 {
    margin: 0;
    margin-top: 1em;
    margin-bottom: .3em;
    font-weight: 800;
    color: #ccc;
}

.submitPage textarea {
    display: block;
    width: calc(100% - 12px);
    border: 2px #333 solid;
    outline: none;
    background: #1a1a1a;
    border-radius: 4px;
    color: #fafafa;
    padding: 4px;
    resize: none;
}

.submitPage input[type=text] {
    width: calc(100% - 12px);
    border: 2px #333 solid;
    outline: none;
    background: #1a1a1a;
    border-radius: 4px;
    color: #fafafa;
    padding: 4px;
}

.submitPage button[type=submit] {
    border-radius: 4px;
    background-color: #ea4a92;
    color: white;
    padding: 8px 32px;
    font-weight: 800;
    border: none;
    cursor: pointer;
    margin-top: 2em;
}

.submitPage button[type=submit]:hover {
    padding: 6px 30px;
    border: 2px white solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.submitPage button[type=submit][disabled] {
    opacity: .6;
}
.submitPage button[type=submit][disabled]:hover {
    border: 2px transparent solid;
    cursor: default;
}