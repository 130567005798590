.fileSection {
    padding: 16px;
    max-width: min(100%, 1400px);
    margin: 0 auto;
}

.fileName {
    margin: 1em 0 0 0;
    font-size: 1.4em;
    font-weight: 800;
}

.reviewCard {
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
}

.reviewCard .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .8em;
    color: #aaa;
}

.reviewCard h2 {
    margin: 0 0 0 0;
    margin-top: .4em;
    margin-right: 6px;
}

.reviewCard .description {
    font-size: 1em;
    color: #bbb;
    margin: 1em 0 0 0;
    line-height: 1.25em;
    vertical-align: top;
}

.repoUrl {
    font-size: .8em;
    color: #aaa;
    background: none;
    border: none;
    cursor: pointer;
}
.repoUrl:hover {
    color:  #ea4a92;
}

.avatar {
    height: 16px;
    width: 16px;
    border-radius: 16px;
}

.userName {
    margin: 0 .4em;
    font-weight: 800;
}

.createdAt {
    margin: 0px 8px;
}

.spacer {
    flex-grow: 1;
}

.comments {
    font-weight: 800;
}

.language {
    border-radius: 20px;
    font-size: .7em;
    font-weight: 600;
    background: rgb(27, 159, 252);
    color: white;
    padding: 1px 6px;
    margin-right: 4px;
}