.userProfileCard {
    margin-top: 2em;
    border-radius: 8px;
    background: #222;
    display: flex;
    box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, .5);
}
.userProfileCard img {
    border-radius: 8px 0 0 8px;
    width: 300px;
    height: 300px;
}

.userProfileCard h2 {
    margin: 0;
    font-size: 3em;
}
.userDetails {
    padding: 20px;
}
.userDetails>* {
    margin-right: 16px;
}

.addBio {
    display: block;

}

.bio {
    margin-top: 16px;
}

.bio>button {
    background: transparent;
    border-radius: 6px;
    border: 2px transparent solid;
    color: white;
    padding-left: 0;
}

.bio>button:hover {
    border: 2px #444 solid;
    cursor: pointer;
}

.bio textarea {
    display: block;
    width: 100%;
    background: #2b2b2b;
    color: white;
    border: 2px #444 solid;
    border-radius: 6px;
}

.bio>form>button {
    margin-right: 8px;
    margin-top: 8px;
    border-radius: 4px;
}