.notificationCard {
    border: none;
    background: #222;
    border-radius: 6px;
    margin: 12px 0;
    padding: 4px 12px;
    padding-top: 12px;
    display: block;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    color: #ddd;
    box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, .2);
}

.notificationCard:hover {
    background: #333;
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, .5);
}

.notificationText {
    display: inline-block;
    margin-bottom: 8px;
} 

.notificationCard>.body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.createdAt {
    color: #999;
    font-size: .9em;
    font-weight: 600;
    flex-grow: 1;
    text-align: right;
}

.emailToggle {
    background: transparent;
    color: #888;
    font-weight: 800;
    cursor: pointer;
    font-size: .8em;
    border: none;
    outline: none;
}


.emailToggle:hover {
    text-decoration: underline;
}

.emailInfo {
    color: #ccc;
    border: 2px #555 dashed;
    padding: 8px;
    border-radius: 6px;
}